import {ref} from "vue";

const helptext_modelpage = ref(`
    <h6>Modellen Bladeren en Selecteren</h6>
<ol>
    <li><strong>Filteren:</strong> Selecteer uit de filteropties in de linker kolom om modellen te filteren op specifieke voorkeuren.</li>
    <li><strong>Technische Filters en Prijsfilter:</strong> Open de technische filters door op het tandwiel-icoon te klikken. Hier kunt u specifieke functies selecteren zoals klittenband, stretchleer of diabetische voering en schoenen die opties bieden voor leestmodificaties en aanpassingen aan de schacht of het onderwerk.
        <ul>
            <li>Naast de technische filters vindt u ook een prijsfilter switch. Standaard worden schoenmodellen getoond die onder een ingesteld prijsplafond vallen. Door de prijsfilter switch om te zetten, worden ook duurdere modellen getoond. Let op, modellen buiten het plafond worden vaak niet volledig door zorgverzekeraars gedekt. Een aanvullende motivatie kan nodig zijn binnen uw organisatie bij het kiezen van deze modellen.</li>
        </ul>
    </li>
    <li><strong>Modeldetails en bestel- of deelopties:</strong> Om een bestelling te plaatsen, één of meerdere modellen te delen of om aanvullende modeldetails weer te geven, klikt u op de foto van een model om de modeldetail popup te openen. In deze popup vindt u aanvullende informatie over het model, inclusief beschikbare lengte- en wijdtematen.
        <ul>
            <li>Linksboven vindt u de deelopties om modellen te delen met cliënten of collega’s.</li>
            <li>Gebruik de ‘Bestellen’ knop binnen de popup om direct een bestelling te plaatsen.</li>
        </ul>
    </li>
</ol>
`);

export {helptext_modelpage}