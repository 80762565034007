<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  onIonViewDidEnter,
  menuController,
  IonToolbar,
  IonMenu,
  IonHeader,
  IonButtons,
  IonButton,
  IonCardContent,
  IonCard,
  IonToggle,
  IonItem,
} from "@ionic/vue";
import { DataStore } from "aws-amplify/datastore";
import { downloadData } from "aws-amplify/storage";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import FtrHeader from "../components/FtrHeader.vue";
import ModelCard from "../components/ModelCard.vue";
import ModelModal from "../components/ModelModal.vue";
import ModelCardSkeleton from "../components/ModelCardSkeleton.vue";
import ModelPageFilter from "../components/ModelpageFilter.vue";
import * as queries from "../graphql/queries";
import {
  getDevModelCollection,
  listDevFootcareModels,
} from "../graphql/queries";
import {
  DevFittrClosing,
  DevFittrColor,
  DevFittrHeight,
  DevFittrStyle,
  DevFittrSpec,
  DevFootcareStyle,
  DevFootcareClosing,
  DevFootcareModel,
} from "../models";
import { useGlobalStore } from "../store/global";
import { helptext_modelpage } from "../locales/HelptTextContent";
// Reactive reference to help text content
const helptext_content = ref(helptext_modelpage);

const route = useRoute();
const router = useRouter();
const store = useGlobalStore();

const colors = ref([]);
const selectedColors = ref([]);

const styles = ref([]);
const selectedStyles = ref([]);

const selectedClosings = ref([]);
const closings = ref([]);

const selectedHeight = ref([]);
const heights = ref([]);

const selectedBrands = ref([]);
const brands = ref([]);

const selectedSpecs = ref([]);
const specs = ref([]);

const addedModels = ref([]);

// Helper function to sort models by price
const sortByPrice = (models) => models.sort((a, b) => a.price - b.price);

// Helper function to get price limit
async function getPriceLimit() {
  let organisation = store.logged_in_user?.["custom:organisation"];

  if (!organisation) {
    if (store.url.includes("kievit")) organisation = "Kievit";
    else if (store.url.includes("penders")) organisation = "Penders";
    else if (store.url.includes("footcare")) organisation = "Footcare";
    else organisation = "Fittr"; // Default to 'Fittr' if none match
  }

  const hasHistory = !router.options.history.state.back;
  const priceLimitObj = await store.getPriceLimit(organisation, hasHistory);
  store.priceLimit = parseInt(priceLimitObj, 10) + 1;
}

// Helper function to initialize models
async function initializeModels(models) {
  if (route.path.includes("osa")) {
    store.models = models.slice(0, 50);
    addedModels.value = models.slice(50);
    store.initialisedModels = models;
  } else {
    await getPriceLimit();
    const sortedModels = sortByPrice(models);
    const cheapModels = sortedModels.filter(
      (model) => model.price < store.priceLimit
    );
    store.expensiveModels = sortedModels;
    store.cheapModels = cheapModels;
    store.models = cheapModels.slice(0, 50);
    addedModels.value = cheapModels.slice(50);
    store.initialisedModels = sortedModels;
  }
}

// Helper function to get filter items
const getFilterItems = async (param, queryFunction) => {
  const uniques = store.findUniqueModelValue(param);

  const results = await Promise.all(
    uniques.map(async (uniqueId) => {
      const tempItem = await queryFunction(uniqueId);

      if (tempItem && param !== "instep_type") {
        return { id: tempItem.id, item: tempItem.item };
      } else if (param === "instep_type") {
        console.log(uniqueId);
      }
      return null;
    })
  );

  return results.filter((item) => item !== null);
};

// Unified function to get OSB data
async function getOSBData(hasHistory = false) {
  let models;
  if (hasHistory) {
    // Fetch online data
    if (route.path.includes("osa")) {
      const filter = {
        category: {
          contains: store.category, // filter priority = 1
        },
      };

      try {
        const response = await client.graphql({
          query: listDevFootcareModels,
          variables: { limit: 500, filter },
        });

        const items = response.data.listDevFootcareModels.items;

        models = items.map((item) => {
          const parsedAttributes = JSON.parse(item.attributes);
          const parsedSearch = JSON.parse(item.search);

          return {
            ...item,
            attributes: parsedAttributes,
            search: parsedSearch,
            modelID: item.id,
          };
        });

        models.sort((a, b) => {
          const x = a.modelnumber.toLowerCase();
          const y = b.modelnumber.toLowerCase();
          return x.localeCompare(y);
        });

        console.log("Online OSA data loaded");
      } catch (error) {
        console.error("Error loading online OSA data:", error);
        return;
      }
    } else {
      try {
        const response = await client.graphql({
          query: getDevModelCollection,
          variables: { id: JSON.parse(route.params.collectionId).collectionId },
        });

        const downloadResult = await downloadData({
          path: "public/" + response.data.getDevModelCollection.models,
          // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
        }).result;
        const text = await downloadResult.body.text();
        // Alternatively, you can use `downloadResult.body.blob()`
        // or `downloadResult.body.json()` get read body in Blob or JSON format.
        models = JSON.parse(text);
        console.log("Online OSB data loaded");
      } catch (error) {
        console.error("Error loading online OSB data:", error);
        return;
      }
    }
  } else {
    // Use DataStore data
    models = store.modelCollection;
    console.log("Store data loaded");
  }

  await initializeModels(models);

  // Define filter parameters and corresponding query functions
  if (route.path.includes("osa")) {
    const filterParams = [
      {
        param: "styles",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFootcareStyle"],
                variables: { id },
              })
              .then((res) => res.data["getDevFootcareStyle"]);
          } else {
            return DataStore.query(DevFootcareStyle, id);
          }
        },
        value: styles,
      },
      {
        param: "closings",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFootcareClosing"],
                variables: { id },
              })
              .then((res) => res.data["getDevFootcareClosing"]);
          } else {
            return DataStore.query(DevFootcareClosing, id);
          }
        },
        value: closings,
      },
    ];

    // Fetch filter items concurrently
    await Promise.all(
      filterParams.map(async ({ param, queryFunction, value }) => {
        value.value = await getFilterItems(param, queryFunction);
      })
    );

    heights.value = [
      { id: "laag", item: "laag", value: "laag" },
      { id: "hoog", item: "hoog", value: "hoog" },
    ];
  } else {
    const filterParams = [
      {
        param: "colors",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFittrColor"],
                variables: { id },
              })
              .then((res) => res.data["getDevFittrColor"]);
          } else {
            return DataStore.query(DevFittrColor, id);
          }
        },
        value: colors,
      },
      {
        param: "styles",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFittrStyle"],
                variables: { id },
              })
              .then((res) => res.data["getDevFittrStyle"]);
          } else {
            return DataStore.query(DevFittrStyle, id);
          }
        },
        value: styles,
      },
      {
        param: "closings",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFittrClosing"],
                variables: { id },
              })
              .then((res) => res.data["getDevFittrClosing"]);
          } else {
            return DataStore.query(DevFittrClosing, id);
          }
        },
        value: closings,
      },
      {
        param: "height",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFittrHeight"],
                variables: { id },
              })
              .then((res) => res.data["getDevFittrHeight"]);
          } else {
            return DataStore.query(DevFittrHeight, id);
          }
        },
        value: heights,
      },
      {
        param: "specs",
        queryFunction: (id) => {
          if (hasHistory) {
            return client
              .graphql({
                query: queries["getDevFittrSpec"],
                variables: { id },
              })
              .then((res) => res.data["getDevFittrSpec"]);
          } else {
            return DataStore.query(DevFittrSpec, id);
          }
        },
        value: specs,
      },
    ];

    // Fetch filter items concurrently
    await Promise.all(
      filterParams.map(async ({ param, queryFunction, value }) => {
        value.value = await getFilterItems(param, queryFunction);
      })
    );

    // Process brands
    const tempBrands = store.findUniqueModelValue("brand");
    brands.value = tempBrands.map((brand) => ({
      item: brand,
      id: brand,
    }));
  }
}

// Update fetchData function
async function fetchData() {
  const hasHistory = !router.options.history.state.back;
  await getOSBData(hasHistory);
}

// Simplify togglePriceFilter function
const togglePriceFilter = async () => {
  store.imageLoadingState = true;
  store.priceFilter = !store.priceFilter;
  console.log(store.priceFilter);

  if (store.priceFilter === true) {
    store.initialisedModels = store.cheapModels;
  } else {
    store.initialisedModels = store.expensiveModels;
  }
  setTimeout(async () => {
    const models = await store.toggleFilter(undefined, undefined, route);
    store.models = models.slice(0, 50);
    store.imageLoadingState = false;
    const addedModels = models.slice(50);
    setTimeout(async () => {
      store.models.push(...addedModels);
    }, 500);
  }, 500);
};

const setActiveModel = (model) => {
  store.activeModel = model;
  store.model = model;
};

const setAllFavorite = () => {
  if (store.favorites.length > 0) {
    store.favorites = [];
  } else {
    store.favorites = store.models.map((model) => model.modelID);
    if (router.options.history.state.back !== "/home") {
      localStorage.setItem("favorites", JSON.stringify(store.favorites));
    }
  }

  if (store.favoritesOpen) {
    showFavorites();
  }
};

const isFavorite = (id) => store.favorites.includes(id);

const setFavorite = (id) => {
  const index = store.favorites.indexOf(id);
  if (index === -1) {
    store.favorites.push(id);
  } else {
    store.favorites.splice(index, 1);
  }

  if (router.options.history.state.back !== "/home") {
    localStorage.setItem("favorites", JSON.stringify(store.favorites));
  }

  if (store.favoritesOpen) {
    showFavorites();
  }
};

function showFavorites() {
  if (store.favorites.length > 0) {
    store.isOpen = true;
    store.activeModels = store.initialisedModels.filter((model) =>
      store.favorites.includes(model.modelID)
    );

    if (store.activeModels.length > 0) {
      setActiveModel(store.activeModels[0]);
      store.setOpen(true);
      store.favoritesOpen = true;
    } else {
      // No matching models found
      $q.notify({
        color: "red-5",
        position: "center",
        textColor: "white",
        icon: "fas fa-triangle-exclamation",
        message: "Geen overeenkomende favorieten gevonden!",
      });
      store.setOpen(false);
    }
  } else {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Er zijn nog geen favorieten geselecteerd!",
    });
    store.setOpen(false);
  }
}

const searchModels = (event) => {
  store.imageLoadingState = true;

  const input = event.target.value ? event.target.value.toLowerCase() : "";

  if (input) {
    const searchResult = store.initialisedModels.filter((model) => {
      const searchString = (
        (model?.modelnumber?.toString() || "") +
        (model?.colornumber?.toString() || "") +
        (model?.colorname?.toString() || "") +
        (model?.modelname?.toString() || "")
      ).toLowerCase();
      return searchString.includes(input);
    });

    if (searchResult.length > 0) {
      store.scrollState = true;
      searchResult.sort((a, b) => {
        const modelnumberComparison = (a.modelnumber || "").localeCompare(
          b.modelnumber || ""
        );
        if (modelnumberComparison !== 0) {
          return modelnumberComparison;
        }
        return (a.colornumber || "").localeCompare(b.colornumber || "");
      });
      store.models = searchResult;
      setTimeout(() => {
        store.imageLoadingState = false;
      }, 1000);
    } else {
      $q.notify({
        color: "red-5",
        position: "center",
        textColor: "white",
        icon: "fas fa-triangle-exclamation",
        message: "Helaas, deze zoekopdracht geeft geen resultaten",
      });
      store.models = [];
      setTimeout(() => {
        event.target.value = "";
      }, 6000);
    }
  } else {
    // If input is empty, reset to initial models
    store.models = store.initialisedModels.slice(0, 50);
    setTimeout(async () => {
      const addedModels = store.initialisedModels.slice(50);
      store.imageLoadingState = false;
      store.models.push(...addedModels);
    }, 1000);
  }
};

const innerWidth = ref(0);
innerWidth.value = window.innerWidth;

const dbModel = ref();
// Implement onIonViewDidEnter
onIonViewDidEnter(async () => {
  console.log("ModelPage did enter");
  // Call fetchData to initiate data loading
  try {
    await fetchData();
    console.log("Data loading complete.");
    store.imageLoadingState = false;
    store.filterLoadingState = false;
    store.models.push(...addedModels.value);
    if (route.path.includes("osa") && route.path.includes("models")) {
      dbModel.value = DevFootcareModel;
    } else if (route.path.includes("osb") && route.path.includes("models")) {
      dbModel.value = store.Models;
    }
  } catch (error) {
    console.error("Error during data loading:", error);
  }
});
</script>

<template>
  <ion-page id="help">
    <ion-menu
      v-if="router.options.history.state.back"
      style="--height: 100%; --width: 35em"
      side="start"
      content-id="men"
      menu-id="men"
      class="my-custom-menu"
    >
      <ion-header :translucent="true">
        <ion-toolbar color="primary">
          <ion-buttons slot="end">
            <ion-button @click="menuController.close('men')">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <div class="scroll" style="width: 100%; height: 100%;overflow-x:hidden ">
        <ModelPageFilter
          propName="specs"
          title="Technische filters"
          :items="specs"
          :model="selectedSpecs"
        />

        <ModelPageFilter
          propName="brand"
          title="Merken"
          :items="brands"
          :model="selectedBrands"
        />

        <ion-card ref="content" style="margin: 12px">
          <ion-card-content>
            <ion-item :detail="false" lines="full">
              <ion-toggle
                @ionChange="togglePriceFilter"
                :checked="store.priceFilter"
                label-placement="start"
                justify="space-between"
                >Prijsfilter
              </ion-toggle>
            </ion-item>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-menu>

    <FtrHeader
      :searchModels="searchModels"
      :showFavorites="showFavorites"
      :setAllFavorites="setAllFavorite"
      :helpText="helptext_modelpage"
      :favoritesButton="true"
      :allFavoritesButton="true"
      :functionalFiltersButton="true"
      :showSearch="true"
    ></FtrHeader>

    <ion-content fullscreen id="men">
      <ion-grid
        slot="fixed"
        style="width: 100%; height: calc(100% + 76px); margin-top: -76px"
      >
        <ion-row style="width: 100%; height: 100%">
          <ion-col
            size-xs="0"
            size-sm="2"
            size-md="3"
            size-lg="3"
            size-xl="2"
            class="scroll overflow-auto row"
            style="
              width: 100%;
              height: 100%;
              margin: 0;
              padding-top: 76px;
              padding-left: 0;
              padding-right: 0;
              padding-bottom: 0;
            "
          >
            <ModelPageFilter
              propName="closings"
              title="Sluiting"
              :items="closings"
              :model="selectedClosings"
            />
            <ModelPageFilter
              v-if="route.path.includes('osb')"
              propName="colors"
              title="Kleurtinten"
              :items="colors"
              :model="selectedColors"
            />
            <ModelPageFilter
              propName="height"
              title="Schoen hoogte"
              :items="heights"
              :model="selectedHeight"
            />
            <ModelPageFilter
              propName="styles"
              title="Style"
              :items="styles"
              :model="selectedStyles"
            />
          </ion-col>

          <ion-col
            size-xs="12"
            size-sm="10"
            size-md="9"
            size-lg="9"
            size-xl="10"
            class="scroll overflow-auto row"
            style="
              padding-top: 76px;
              padding-left: 0;
              padding-right: 0;
              padding-bottom: 0;
              width: 100%;
              height: 100%;
              margin: 0;
            "
          >
            <template v-if="store.imageLoadingState">
              <ModelCardSkeleton v-for="i in 15" :key="'skel' + i" />
            </template>

            <template v-else-if="!store.imageLoadingState">
              <ModelCard
                v-for="item in store.models"
                :key="'card' + item?.modelID"
                :item="item"
                :setFavorite="setFavorite"
                :isFavorite="isFavorite"
                :dbModel="dbModel"
              />
            </template>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ModelModal
        v-if="store.isOpen"
        :innerWidth="innerWidth"
        :setFavorite="setFavorite"
      />
    </ion-content>
  </ion-page>
</template>

<style scoped></style>
